<template>
  <div>
    <div style="min-height: 250px">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">{{ $t("model.prop.no") }}</th>
              <th class="text-left">{{ $t("model.prop.name") }}</th>
              <th class="text-right"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in students" :key="i">
              <td>{{ i + 1 }}</td>
              <td>{{ item }}</td>
              <td class="text-right">
                <v-btn @click="remove(i)" color="red" text>
                  {{ $t("action.remove") }}</v-btn
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
export default {
  props: ["callbackSuccess", "students"],
  data: () => ({
   
  }),
  created() {
  },
  methods: {
    remove(key) {
      this.students.splice(key, 1);
      if (this.students.length == 0) {
        this.$emit("close");
      }
    },
   
    cancel() {
      this.$emit("close");
    },
  },
};
</script>

<style>
</style>